import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import './exilium-element.scss';

interface IProps {
  rarity: string;
}

export const GFLRarity: React.FC<IProps> = ({ rarity }) => {
  const popoverrarity = (
    <Popover id="popover-rarity">
      <Popover.Header as="h3">{rarity}</Popover.Header>
      <Popover.Body>
        {rarity} is one of the Characters rarities available in GFL 2: Exilium.
      </Popover.Body>
    </Popover>
  );
  return (
    <OverlayTrigger
      trigger="click"
      rootClose
      placement="auto-start"
      overlay={popoverrarity}
    >
      <div className={`gfl-rarity rar-${rarity}`}>{rarity}</div>
    </OverlayTrigger>
  );
};
