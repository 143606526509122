import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { IGFLCharacter } from '../../common/model/graphql-types';
import { GFLCharacterIcon } from './exilium-character-icon';
import { GFLCharacterCard } from './exilium-character-card';

interface IProps {
  id?: string;
  slug: string;
  enablePopover?: boolean;
  showLabel?: boolean;
  showIcon?: boolean;
  showTags?: boolean;
  mode: string;
  unitId?: string;
}

export const GFLCharacter: React.FC<IProps> = ({
  id,
  unitId,
  mode,
  showLabel,
  showIcon,
  slug,
  enablePopover
}) => {
  const allGflCharacters = useStaticQuery(graphql`
    query {
      allContentfulGflCharacter {
        nodes {
          id
          unitId
          slug
          name
          rarity
          class
          weapon
          weaponName
          smallImage {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          cardImage {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `);
  if ((!id && !slug && !unitId) || !mode) {
    return <div>Err</div>;
  }

  const character = allGflCharacters.allContentfulGflCharacter.nodes.find(
    (emp: IGFLCharacter) =>
      id ? emp.id === id : unitId ? emp.unitId === unitId : emp.slug === slug
  );

  if (!character) {
    return <div>Err</div>;
  }

  return (
    <>
      {mode === 'icon' && (
        <GFLCharacterIcon
          employee={character}
          showLabel={showLabel}
          showIcon={showIcon}
          enablePopover={enablePopover}
        />
      )}
      {mode === 'card' && (
        <GFLCharacterCard
          employee={character}
          showLabel={showLabel}
          enablePopover={enablePopover}
        />
      )}
    </>
  );
};
