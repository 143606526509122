import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import './exilium-element.scss';
import { StaticImage } from 'gatsby-plugin-image';

interface IProps {
  classChar: string;
}

export const GFLClass: React.FC<IProps> = ({ classChar }) => {
  const popoverclassChar = (
    <Popover id="popover-classChar">
      <Popover.Header as="h3">{classChar}</Popover.Header>
      <Popover.Body>
        {classChar} is one of the Characters rarities available in GFL 2:
        Exilium.
      </Popover.Body>
    </Popover>
  );
  return (
    <OverlayTrigger
      trigger="click"
      rootClose
      placement="auto-start"
      overlay={popoverclassChar}
    >
      <div className={`gfl-class ${classChar}`}>
        {classChar === 'Bulwark' && (
          <>
            <StaticImage
              src="../../../images/exilium/icons/class_bulwark.png"
              alt={classChar}
            />
          </>
        )}
        {classChar === 'Sentinel' && (
          <>
            <StaticImage
              src="../../../images/exilium/icons/class_sentinel.png"
              alt={classChar}
            />
          </>
        )}
        {classChar === 'Support' && (
          <>
            <StaticImage
              src="../../../images/exilium/icons/class_support.png"
              alt={classChar}
            />
          </>
        )}
        {classChar === 'Vanguard' && (
          <>
            <StaticImage
              src="../../../images/exilium/icons/class_vanguard.png"
              alt={classChar}
            />
          </>
        )}
        {classChar}
      </div>
    </OverlayTrigger>
  );
};
