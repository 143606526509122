import React, { useEffect, useState } from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';

import './index.scss';
import './characters.scss';
import '../generic-page.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { Link, graphql } from 'gatsby';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IGFLCharacter } from '../../modules/common/model/graphql-types';
import lodash from 'lodash';
import { Card } from 'react-bootstrap';
import { FilterBar } from '../../modules/cs/common/components/filter-bar/filter-bar';
import { GFLCharacter } from '../../modules/exilium/components/exilium-character';

interface IZZZCharacterNodes {
  nodes: IGFLCharacter[];
}

interface IZZZCharacterEntry {
  allCharacters: IZZZCharacterNodes;
}

interface IProps {
  data: IZZZCharacterEntry;
}

const ExiliumCharaPage: React.FC<IProps> = ({ data }) => {
  const [visibleCharacters, setVisibleCharacters] = useState(
    data.allCharacters.nodes
  );

  const [activeFilters, setActiveFilters] = useState(null);
  const [sortOption, setSortOption] = useState(null);

  const filterConfig = {
    filters: [
      {
        key: 'searchText',
        type: 'search',
        placeholder: 'Search characters...'
      },
      {
        key: 'rarity',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          { label: 'SR', value: 'SR' },
          { label: 'SSR', value: 'SSR' }
        ]
      },
      {
        key: 'class',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Bulwark',
            tooltip: 'Bulwark',
            image: (
              <StaticImage
                src="../../images/exilium/icons/class_bulwark.png"
                width={24}
                alt="Bulwark"
              />
            )
          },
          {
            value: 'Sentinel',
            tooltip: 'Sentinel',
            image: (
              <StaticImage
                src="../../images/exilium/icons/class_sentinel.png"
                width={24}
                alt="Sentinel"
              />
            )
          },
          {
            value: 'Support',
            tooltip: 'Support',
            image: (
              <StaticImage
                src="../../images/exilium/icons/class_support.png"
                width={24}
                alt="Support"
              />
            )
          },
          {
            value: 'Vanguard',
            tooltip: 'Vanguard',
            image: (
              <StaticImage
                src="../../images/exilium/icons/class_vanguard.png"
                width={24}
                alt="Vanguard"
              />
            )
          }
        ]
      },
      {
        key: 'weapon',
        type: 'dropdown',
        values: [
          { label: 'Any Weapon', value: 'all' },
          {
            label: 'Assault Rifle',
            value: 'Assault Rifle'
          },
          {
            label: 'Handgun',
            value: 'Handgun'
          },
          {
            label: 'LMG',
            value: 'LMG'
          },
          {
            label: 'Shotgun',
            value: 'Shotgun'
          },
          {
            label: 'SMG',
            value: 'SMG'
          },
          {
            label: 'Sniper Rifle',
            value: 'Sniper Rifle'
          },
          {
            label: 'Sword',
            value: 'Sword'
          }
        ]
      },
      { key: 'reset', type: 'reset' }
    ],
    defaults: null
  };
  const handleFilterUpdates = (choices) => {
    setActiveFilters({
      ...choices
    });
  };
  useEffect(() => {
    let filtered = data.allCharacters.nodes;

    if (activeFilters) {
      if (activeFilters.searchText) {
        filtered = filtered.filter(
          (emp) =>
            emp.name
              .toLowerCase()
              .indexOf(activeFilters.searchText.toLowerCase()) > -1
        );
        filtered = lodash.uniqBy(filtered, 'unitId');
      }
      if (activeFilters.rarity && activeFilters.rarity !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.rarity.toLowerCase() === activeFilters.rarity.toLowerCase()
        );
      }
      if (activeFilters.class && activeFilters.class !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.class.toLowerCase() === activeFilters.class.toLowerCase()
        );
      }
      if (activeFilters.weapon && activeFilters.weapon !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.weapon.toLowerCase() === activeFilters.weapon.toLowerCase()
        );
      }
    }

    if (sortOption === '+fullName') {
      filtered = filtered.sort((a, b) => (a.name > b.name ? 1 : -1));
    } else if (sortOption === '-fullName') {
      filtered = filtered.sort((a, b) => (a.name < b.name ? 1 : -1));
    }

    setVisibleCharacters(filtered);
  }, [activeFilters, data.allCharacters.nodes, sortOption]);

  const sortingOptions = [
    { value: '+fullName', label: 'Sort by A-Z' },
    { value: '-fullName', label: 'Sort by Z-A' }
  ];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const sortChangeHandler = (e) => {
    setSortOption(e.value);
  };

  let defaultSortOption = sortOption
    ? sortingOptions.find((opt) => opt.value === sortOption)
    : null;
  if (defaultSortOption === null) {
    defaultSortOption = sortingOptions[0];
    setSortOption(defaultSortOption.value);
  }

  return (
    <DashboardLayout className={'generic-page characters-gfl'} game="exilium">
      <ul className="breadcrumb">
        <li>
          <Link to="/gfl-exilium/">Girls' Frontline 2: Exilium</Link>
        </li>
        <li className="divider">/</li>
        <li>Characters List</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/exilium/categories/category_characters.jpg"
            alt="Characters"
          />
        </div>
        <div className="page-details">
          <h1>Girls' Frontline 2: Exilium Characters List</h1>
          <h2>
            Characters are obtainable units in Girls' Frontline 2: Exilium -
            find every character available in the game below!
          </h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <SectionHeader
        title="Characters"
        subtitle={
          <span className="additional-text">
            Showing {visibleCharacters.length} Character
            {visibleCharacters.length !== 1 && 's'}
          </span>
        }
      />
      <div className="employees-filter-bar gfl">
        <FilterBar config={filterConfig} onChange={handleFilterUpdates} />
      </div>
      <div className="banner ready">
        <div className="cta">
          <h4>Global version of GFL: Exilium has been announced!</h4>
          <p>
            We're currently working on updating our database with all missing
            characters and their details. Stay tuned!
          </p>
        </div>
      </div>
      <div className={`employees-container gfl-cards`}>
        {visibleCharacters.map((emp, index) => {
          return (
            <span key={index}>
              <Card className="avatar-card">
                <GFLCharacter
                  slug={emp.slug}
                  mode="card"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </span>
          );
        })}
      </div>
      {visibleCharacters.length === 0 && (
        <div className="no-results">
          <StaticImage
            src="../../images/exilium/no_results.jpg"
            alt="No results"
          />
          <p>No characters found. Try changing your filter.</p>
        </div>
      )}
      <br /> <br />
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
    </DashboardLayout>
  );
};

export default ExiliumCharaPage;

export const Head: React.FC = () => (
  <Seo
    title="Characters | Girls' Frontline 2: Exilium | Prydwen Institute"
    description="Characters are obtainable units in Girls' Frontline 2: Exilium - find every character available in the game below!"
    game="exilium"
  />
);

export const pageQuery = graphql`
  query {
    allCharacters: allContentfulGflCharacter(
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        id
        unitId
        slug
        name
        rarity
        class
        weapon
        weaponName
      }
    }
  }
`;
