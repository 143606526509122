import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import './reset-button.scss';

interface IProps {
  onReset(): void;
  disabled: boolean;
}

export const ResetButton: React.FC<IProps> = ({ onReset, disabled }) => {
  return (
    <>
      <Button
        disabled={disabled}
        onClick={onReset}
        variant="secondary"
        className="reset-button"
      >
        <FontAwesomeIcon icon={faTimes} width="18" /> Reset
      </Button>
    </>
  );
};
