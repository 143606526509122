import React, { useState, useEffect } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { CustomPopover } from '../custom-popover';

import './button-bar.scss';
import { IConfig } from './filter-bar';

interface IProps {
  config: IConfig;
  onChange(config: IConfig, value: string): void;
  resetTrigger: number;
  defaults: IConfig[];
}

export const ButtonBar: React.FC<IProps> = ({
  config,
  onChange,
  resetTrigger,
  defaults
}) => {
  const [currentValue, setCurrentValue] = useState(null);

  useEffect(() => {
    if (
      currentValue === null &&
      config &&
      config.values &&
      config.values.length > 0
    ) {
      setCurrentValue(
        defaults && defaults[config.key]
          ? defaults[config.key]
          : config.values[0].value
      );
    }
  }, [currentValue, config, defaults]);

  useEffect(() => {
    if (resetTrigger !== 0) {
      // check if its the default effect that gets triggered on load since resetTrigger will be 0 at initial and set to current time in secs on actual reset triggers
      setCurrentValue(null);
    }
  }, [resetTrigger]);

  const changeHandler = (e) => {
    setCurrentValue(e.value);
    onChange(config, e.value);
  };

  return (
    <>
      <ButtonGroup>
        {config.values.map((btn, index) => {
          if (!btn.tooltip) {
            return (
              <Button
                variant="secondary"
                onClick={() => changeHandler(btn)}
                key={index}
                value={btn.value}
                className={`${btn.value}`}
                active={btn.value === currentValue}
              >
                {btn.image && btn.image}
                {btn.label}
              </Button>
            );
          } else {
            return (
              <CustomPopover
                customClass="simple"
                enabled
                popover={btn.tooltip}
                key={index}
              >
                <Button
                  variant="secondary"
                  onClick={() => changeHandler(btn)}
                  value={btn.value}
                  className={`${btn.value}`}
                  active={btn.value === currentValue}
                >
                  {btn.image && btn.image}
                  {btn.label}
                </Button>
              </CustomPopover>
            );
          }
        })}
      </ButtonGroup>
    </>
  );
};
