import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Alert } from 'react-bootstrap';
import { IGFLCharacter } from '../../common/model/graphql-types';
import { GFLRarity } from './exilium-rarity';
import { GFLClass } from './exilium-class';
import { GFLWeapon } from './exilium-weapon';

interface IProps {
  character: IGFLCharacter;
}

export const GFLCharacterPopover: React.FC<IProps> = ({ character }) => {
  const image = character.smallImage
    ? getImage(character.smallImage.localFile.childImageSharp)
    : null;
  return (
    <div className="character-popover gfl">
      <div className="top gfl">
        <div className={`avatar gfl rarity-${character.rarity}`}>
          {image && (
            <GatsbyImage
              className="disable-transition"
              image={image}
              alt={`${character.name}`}
            />
          )}
        </div>
        <p className="name">
          <span className="emp-name">{character.name}</span>
        </p>
        <div className="type-class">
          <GFLRarity rarity={character.rarity} />
          <GFLClass classChar={character.class} />
          <GFLWeapon weapon={character.weapon} />
        </div>
      </div>
      <Alert variant="primary">
        <p>The ratings for this character aren't available yet.</p>
      </Alert>
    </div>
  );
};
