import React, { useState, useEffect } from 'react';
import { IConfig } from './filter-bar';

interface IProps {
  config: IConfig;
  onChange(config: IConfig, value: string): void;
  resetTrigger: number;
  defaults: IConfig[];
}

export const TextSearch: React.FC<IProps> = ({
  config,
  onChange,
  resetTrigger,
  defaults
}) => {
  const [currentValue, setCurrentValue] = useState('');
  const [defaultLoaded, setDefaultLoaded] = useState(false);

  useEffect(() => {
    if (currentValue === '' && config && !defaultLoaded) {
      setCurrentValue(
        defaults && defaults[config.key] ? defaults[config.key] : ''
      );
      setDefaultLoaded(true);
    }
  }, [currentValue, config, defaults, defaultLoaded]);

  useEffect(() => {
    if (resetTrigger !== 0) {
      // check if its the default effect that gets triggered on load since resetTrigger will be 0 at initial and set to current time in secs on actual reset triggers
      setCurrentValue('');
    }
  }, [resetTrigger]);

  const changeHandler = (e) => {
    setCurrentValue(e.target.value);
    onChange(config, e.target.value);
  };

  const onClear = () => {
    setCurrentValue('');
    onChange(config, '');
  };

  return (
    <>
      <input
        onChange={changeHandler}
        value={currentValue}
        type="text"
        placeholder={config.placeholder}
        className="search form-control"
      />
      <span
        role="button"
        tabIndex={0}
        className="clear"
        onClick={onClear}
        onKeyDown={onClear}
      >
        &times;
      </span>
    </>
  );
};
