/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image';
import { CustomPopover } from '../../cs/common/components/custom-popover';
import { IGFLCharacter } from '../../common/model/graphql-types';
import { GFLCharacterPopover } from './exilium-character-popover';

interface IProps {
  employee: IGFLCharacter;
  enablePopover?: boolean;
  showLabel?: boolean;
  showIcon?: boolean;
}

export const GFLCharacterIcon: React.FC<IProps> = ({
  employee,
  showLabel,
  enablePopover
}) => {
  const image = employee.smallImage
    ? getImage(employee.smallImage.localFile.childImageSharp)
    : null;
  return (
    <CustomPopover
      enabled={enablePopover}
      customClass="character-hover-box gfl"
      popover={<GFLCharacterPopover character={employee} />}
    >
      <Link to={'/gfl-exilium/characters/' + employee.slug}>
        <div
          className={`avatar gfl rarity-${employee.rarity} ${
            showLabel ? showLabel : ''
          }`}
        >
          {image && (
            <GatsbyImage
              className="disable-transition"
              image={image}
              alt={`${employee.name}`}
            />
          )}
        </div>
        {showLabel && <span className="emp-name">{employee.name}</span>}
      </Link>
    </CustomPopover>
  );
};
