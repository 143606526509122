import React from 'react';

import './exilium-element.scss';

interface IProps {
  weapon: string;
}

export const GFLWeapon: React.FC<IProps> = ({ weapon }) => {
  return <div className={`gfl-weapon ${weapon}`}>{weapon}</div>;
};
