import React, { useState, useEffect } from 'react';
import { TextSearch } from './text-search';
import { ButtonBar } from './button-bar';
import { DropDown } from './drop-down';
import { ResetButton } from './reset-button';
import { useClipboard } from 'use-clipboard-copy';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

import './filter-bar.scss';

interface IConfigValues {
  tooltip?: string;
  value?: string;
  label?: string;
  image?: React.ReactNode;
}

export interface IConfig {
  key?: string;
  placeholder?: string;
  values?: IConfigValues[];
  type?: string;
  isDisabled?: boolean;
  isSearchable?: boolean;
}

interface IFilters {
  filters: IConfig[];
  defaults: IConfig[];
}

interface IProps {
  config: IFilters;
  onChange(choices: IConfig[]): void;
}

export const FilterBar: React.FC<IProps> = ({ config, onChange }) => {
  const [clipboardResult, setClipboardResult] = useState('primary');
  const [clipboardIcon, setClipboardIcon] = useState(faCopy);

  const clipboard = useClipboard({
    onSuccess() {
      setClipboardResult('success');
      setClipboardIcon(faCheck);
      setTimeout(() => {
        setClipboardResult('primary');
        setClipboardIcon(faCopy);
      }, 1500);
    },
    onError() {
      console.log('Failed to copy text!');
      setClipboardResult('error');
      setClipboardIcon(faTimes);
      setTimeout(() => {
        setClipboardResult('primary');
        setClipboardIcon(faCopy);
      }, 1500);
    }
  });

  const [filters, setFilters] = useState(config.filters);
  const [choices, setChoices] = useState(config.defaults || []);

  const [resetProp, triggerReset] = useState(0);

  useEffect(() => {
    setFilters(config.filters);
  }, [config, setFilters]);

  const handleOnChange = (config, value) => {
    const currentChoices = choices;
    currentChoices[config.key] = value;

    setChoices(currentChoices);
    onChange(choices);
  };

  const handleOnReset = () => {
    setChoices([]);
    triggerReset(Date.now());
    onChange([]);
  };

  const handleOnCopyToClipboard = () => {
    if (typeof window !== 'undefined') {
      clipboard.copy(window.location.href);
    }
  };

  return (
    <>
      {filters.map((filter, index) => {
        return (
          <div
            className={`filter-bar-element ${filter.type} element-${index}`}
            key={index}
          >
            {filter.type === 'search' && (
              <TextSearch
                config={filter}
                defaults={config.defaults || []}
                resetTrigger={resetProp}
                onChange={handleOnChange}
              />
            )}
            {filter.type === 'button_bar' && (
              <ButtonBar
                config={filter}
                defaults={config.defaults || []}
                resetTrigger={resetProp}
                onChange={handleOnChange}
              />
            )}
            {filter.type === 'dropdown' && (
              <DropDown
                config={filter}
                defaults={config.defaults || []}
                resetTrigger={resetProp}
                onChange={handleOnChange}
              />
            )}
            {filter.type === 'reset' && (
              <ResetButton
                onReset={handleOnReset}
                disabled={Object.keys(choices).length === 0}
              />
            )}
            {filter.type === 'copy-to-clipboard' && (
              <Button
                variant={clipboardResult}
                onClick={handleOnCopyToClipboard}
                disabled={typeof window === 'undefined'}
              >
                <FontAwesomeIcon icon={clipboardIcon} width="18" /> Copy
              </Button>
            )}
          </div>
        );
      })}
    </>
  );
};
