import React, { useState, useEffect } from 'react';
import Select, { components } from 'react-select';

import './drop-down.scss';
import { IConfig } from './filter-bar';

interface IProps {
  config: IConfig;
  onChange(config: IConfig, value: string): void;
  resetTrigger?: number | boolean;
  defaults?: IConfig[];
}

const Option = (props) => {
  return (
    <components.Option {...props}>
      {props.data.image && props.data.image}
      {props.data.label}
    </components.Option>
  );
};

const SingleValue = (props) => (
  <components.SingleValue {...props}>
    {props.data.image && props.data.image}
    {props.data.label}
  </components.SingleValue>
);

export const DropDown: React.FC<IProps> = ({
  config,
  onChange,
  resetTrigger,
  defaults
}) => {
  const [currentValue, setCurrentValue] = useState(null);

  useEffect(() => {
    if (
      currentValue === null &&
      config &&
      config.values &&
      config.values.length > 0
    ) {
      setCurrentValue(
        defaults && defaults[config.key]
          ? config.values.find((v) => v.value === defaults[config.key])
          : config.values[0]
      );
    }
  }, [currentValue, config, defaults]);

  useEffect(() => {
    if (resetTrigger !== 0) {
      // check if its the default effect that gets triggered on load since resetTrigger will be 0 at initial and set to current time in secs on actual reset triggers
      setCurrentValue(null);
    }
  }, [resetTrigger]);

  const changeHandler = (e) => {
    setCurrentValue(e);
    onChange(config, e.value);
  };

  return (
    <Select
      options={config.values}
      onChange={changeHandler}
      className="custom-dropdown"
      classNamePrefix="custom-dropdown-inner"
      value={currentValue}
      components={{ Option, SingleValue }}
      isDisabled={config.isDisabled}
      isSearchable={config.isSearchable || false}
    />
  );
};
